.App {
  text-align: center;
  left: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo{
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-height: 35px;
}

.logo1{
  position: absolute;
  top: 10px;
  left: 10px;
  max-height: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}



.movieImage {
  transition: transform 0.2s ease-in-out;
  border-radius: 4px;
  object-fit: cover;
  opacity: 0;
  min-width: 230px;
  max-width: 230px;
  max-height: 345px;
  min-height: 345px;
  margin-top: 41px;
}


.movieImage:hover {
  transform: scale(1.05);
  cursor: pointer;
  border: 4px solid hsla(0, 18%, 97%, 0.897);
}


.movieImage.fade-in {
  opacity: 1;
}

.imageContainer{
  height: 400px;
}

.wrapperDiv{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 30px;
  max-width: 240;
}


.fade-container {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.fade-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  animation: fadeAnimation 2s linear infinite;
}
.filling-div {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #fff;
  overflow: hidden;
}

.filling-div::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: #000;
  animation: fillAnimation 3s linear forwards;
}

@keyframes fillAnimation {
  0% {
    width: 0;
    height: 0;
    border-radius: 50%;
  }
  100% {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

#canvas {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: #ccc;
}

#canvasWrapper{
  min-height: 410px;
  margin-top: 41px;
}

#loadingCanvas{
  border-radius: 4px;
}

.wrapperDiv {
  height: 100%;
  position: absolute;
}

.movieName {
  position: relative;
  z-index: 1;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}


.infoField {
  position: absolute;
  min-height: 41px;
  max-width: 230px;
  letter-spacing: 3px;
  top: 510px;
}

.infoField-content {
  transition: transform 1s ease-out, opacity 0.4s ease-out;
  will-change: transform, opacity; /* Optimizes performance during transitions */
}

.fade-out {
  opacity: 0;
  transform: translateY(-30px); /* Smooth downward movement */
}


.footer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: solid #556677;
  position: fixed;
  bottom: 0px;
  width: 100%;
  letter-spacing: .01em;
  font-size: 13px;
}

@media (max-height: 612px) {
  .footer {
    position: static;
    margin-top: 10px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header{
  margin-top: 15px;
  font-size: 26px;
}

.slogan{
  font-size: 15px;
}
